import { hamburguer } from './Hamburguer.variants'

interface HamburguerProps {
  ariaControls: string
  active: boolean
  toggle: () => void
}

const Hamburguer = ({ ariaControls, active, toggle }: HamburguerProps) => {
  const tv = hamburguer({ active })
  const ariaLabel = active ? 'Fechar menu' : 'Abrir menu'

  return (
    <button
      className={tv.base()}
      type="button"
      aria-label={ariaLabel}
      aria-controls={ariaControls}
      aria-expanded={active}
      onClick={toggle}
    >
      <i aria-hidden="true" className={tv.bar()}></i>
    </button>
  )
}

export default Hamburguer
