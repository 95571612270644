import { tv, type VariantProps } from 'tailwind-variants'

export const hamburguer = tv({
  slots: {
    base: [
      'flex',
      'w-7 h-7',
      'shrink-0',
      'cursor-pointer',
      'place-items-center',
      'rounded-md',
      'border-none',
      'p-0.5',
      'shadow-none',
      'outline-none'
    ],
    bar: [
      'relative',
      'block before:block after:block',
      'w-full h-0.5 before:w-full after:w-full before:h-full after:h-full',
      'rounded-sm before:rounded-sm after:rounded-sm',
      'bg-blue-500 before:bg-blue-500 after:bg-blue-500',
      'transition duration-300 ease-out',
      'before:content-[""] after:content-[""]',
      'before:absolute after:absolute'
    ]
  },
  variants: {
    active: {
      false: {
        bar: 'before:-top-2 after:-bottom-2'
      },
      true: {
        bar: 'rotate-45 before:top-0 before:rotate-90 after:bottom-0 after:rotate-90'
      }
    }
  },
  defaultVariants: {
    active: false
  }
})

export type HamburguerVariantProps = VariantProps<typeof hamburguer>
