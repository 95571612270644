import { tv, type VariantProps } from 'tailwind-variants'

export const spinner = tv({
  base: ['flex', 'shrink-0', 'animate-spin'],
  variants: {
    size: {
      xs: 'w-2 h-2',
      sm: 'w-3 h-3',
      md: 'w-4 h-4',
      lg: 'w-5 h-5'
    }
  },
  defaultVariants: {
    size: 'md'
  }
})

export type SpinnerVariantProps = VariantProps<typeof spinner>
