'use client'
import { useEffect, useMemo, useRef, useState } from 'react'
import NextLink from 'next/link'
import { useWindowSize } from 'react-use'
import { useDisableScroll } from '@/hooks/use-disable-scroll'
import { Brand } from '@/svg/Brand'
import { Hamburguer } from '@/components/helpers/Hamburguer'
import { Button } from '@/components/ui/Button'
import { header } from './Header.variants'
import { headerLinks } from './Header.links'

const minWidthForDesktopMenu = 1024

const Header = () => {
  const [opened, setOpened] = useState(false)
  const menuRef = useRef<HTMLElement>(null)
  const { width } = useWindowSize()

  useDisableScroll(opened, menuRef)

  const isDesktopAndOpenedMenu = useMemo(
    () => width >= minWidthForDesktopMenu && opened,
    [width, opened]
  )

  useEffect(() => {
    isDesktopAndOpenedMenu && setOpened(false)
  }, [isDesktopAndOpenedMenu, width])

  const tv = header({ opened })

  return (
    <header className={tv.base()}>
      <div className={tv.container()}>
        <NextLink title="SC Tecelagem" href="/">
          <Brand className={tv.brand()} />
        </NextLink>
        <nav id="header-nav" ref={menuRef} className={tv.nav()}>
          <ul className={tv.menu()}>
            {headerLinks.map(({ href, title }) => (
              <li key={href} className={tv.menuItem()}>
                <Button
                  as="link"
                  activeClassName="font-bold"
                  href={href}
                  title={title}
                  color="primary"
                  onClick={() => setOpened(!opened)}
                >
                  {title}
                </Button>
              </li>
            ))}
          </ul>
        </nav>
        <div className={tv.cta()}>
          <Button
            as="a"
            href="tel:8334442116"
            appearance="solid"
            color="primary"
            title="83 3444-2116"
          >
            83 3444-2116
          </Button>
        </div>
        <div className={tv.hamburguer()}>
          <Hamburguer
            active={opened}
            toggle={() => setOpened((prev) => !prev)}
            ariaControls="header-nav"
          />
        </div>
      </div>
    </header>
  )
}

export default Header
