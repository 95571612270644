import { isBrowser } from './env'

export type GoogleEventProps = {
  eventName: string
  eventParams: Record<string, unknown>
}

export const dispatchGoogleEvent = (
  eventName: GoogleEventProps['eventName'],
  eventParams: GoogleEventProps['eventParams']
) => {
  if (!isBrowser) return

  // @ts-ignore
  window.dataLayer.push({
    event: eventName,
    ...eventParams
  })
}
