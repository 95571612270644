'use client'
import { useEffect, RefObject } from 'react'

export const useDisableScroll = (
  shouldLock: boolean,
  exceptionRef: RefObject<HTMLElement>
) => {
  useEffect(() => {
    const originalOverflow = window.getComputedStyle(document.body).overflow
    const exceptionRefCurrent = exceptionRef.current

    if (!exceptionRefCurrent) return

    if (shouldLock) {
      document.body.style.overflow = 'hidden'

      exceptionRefCurrent.style.overflow =
        window.getComputedStyle(exceptionRefCurrent).overflow === 'hidden'
          ? 'auto'
          : window.getComputedStyle(exceptionRefCurrent).overflow
    } else {
      document.body.style.overflow = originalOverflow
      exceptionRefCurrent.style.overflow =
        window.getComputedStyle(exceptionRefCurrent).overflow
    }

    return () => {
      document.body.style.overflow = originalOverflow

      exceptionRefCurrent.style.overflow =
        window.getComputedStyle(exceptionRefCurrent).overflow
    }
  }, [shouldLock, exceptionRef])
}
