export const headerLinks = [
  {
    href: '/',
    title: 'Início'
  },
  {
    href: '/produtos',
    title: 'Nossos produtos'
  },
  {
    href: '/institucional',
    title: 'Institucional'
  },
  {
    href: '/blog',
    title: 'Blog'
  },
  {
    href: '/contato',
    title: 'Fale conosco'
  }
]
