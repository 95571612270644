'use client'
import { AnchorHTMLAttributes, ReactNode } from 'react'
import clsx from 'clsx'
import { usePathname } from 'next/navigation'
import NextLink, { type LinkProps as NextLinkProps } from 'next/link'

export interface LinkProps
  extends NextLinkProps,
    Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'children' | 'href' | 'color'> {
  children?: ReactNode
  className?: string
  activeClassName?: string
}

const Link = ({
  children,
  href,
  className = '',
  activeClassName = '',
  ...rest
}: LinkProps) => {
  const currentRoute = usePathname()

  const isActive = () => {
    return (
      href === currentRoute ||
      (typeof href === 'string' && href !== '/' && currentRoute.includes(href))
    )
  }

  return (
    <NextLink
      href={href}
      className={clsx(className, {
        [activeClassName]: isActive()
      })}
      {...rest}
    >
      {children}
    </NextLink>
  )
}

export default Link
