'use client'
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  MouseEventHandler,
  ReactNode,
  memo
} from 'react'
import { GoogleEventProps, dispatchGoogleEvent } from '@/utils/google'
import { Link, type LinkProps } from '@/components/helpers/Link'
import { Spinner } from '@/components/helpers/Spinner'
import { button, type ButtonVariantProps } from './Button.variants'

interface BaseButtonProps extends ButtonVariantProps {
  children?: ReactNode
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  googleEvent?: GoogleEventProps
  disabled?: boolean
}

interface AsButton
  extends BaseButtonProps,
    Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseButtonProps> {
  as: 'button'
}

interface AsLink
  extends BaseButtonProps,
    Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof BaseButtonProps> {
  as: 'a'
}

interface AsNextLink extends BaseButtonProps, LinkProps {
  as: 'link'
}

export type ButtonProps = AsButton | AsLink | AsNextLink

const Button = ({
  as,
  children,
  className = '',
  appearance = 'text',
  color = 'dark',
  size = 'md',
  onClick,
  fullWidth = false,
  isLoading = false,
  disabled = false,
  leftIcon,
  rightIcon,
  googleEvent,
  ...rest
}: ButtonProps) => {
  const tv = button({
    appearance,
    color,
    size,
    fullWidth,
    isLoading,
    isIconOnly: !children && (!!leftIcon || !!rightIcon)
  })

  const handleOnClick: MouseEventHandler<any> = (e) => {
    !!onClick && onClick(e)
    !!googleEvent && dispatchGoogleEvent(googleEvent.eventName, googleEvent.eventParams)
  }

  const renderChildren = () => {
    return (
      <>
        {isLoading && <Spinner className="text-white" />}
        {!!leftIcon && <i className={tv.icon()}>{leftIcon}</i>}
        {children}
        {!!rightIcon && <i className={tv.icon()}>{rightIcon}</i>}
      </>
    )
  }

  if (as === 'button') {
    return (
      <button
        {...(rest as AsButton)}
        className={tv.base({ className })}
        onClick={(e) => handleOnClick(e)}
        disabled={isLoading || disabled}
      >
        {renderChildren()}
      </button>
    )
  }

  if (as === 'link') {
    return (
      <Link
        {...(rest as AsNextLink)}
        className={tv.base({ className })}
        onClick={(e) => handleOnClick(e)}
      >
        {renderChildren()}
      </Link>
    )
  }

  return (
    <a
      {...(rest as AsLink)}
      className={tv.base({ className })}
      onClick={(e) => handleOnClick(e)}
    >
      {renderChildren()}
    </a>
  )
}

export default memo(Button)
