export const Brand = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 385 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M259.358 105.115C257.834 103.905 256.356 103.363 254.584 103.363V103.357C250.258 103.357 246.874 106.834 246.874 111.067C246.874 115.299 250.345 118.75 254.604 118.75C255.981 118.75 257.386 118.362 258.462 117.693C258.877 117.446 259.311 117.111 259.786 116.657C260.308 116.202 260.421 116.055 260.756 115.6L260.849 115.473L261.023 115.446H262.334L262.066 115.908C260.642 118.342 257.646 119.98 254.611 119.98C249.636 119.98 245.59 115.955 245.59 111.033C245.59 106.112 249.636 102.113 254.611 102.113C257.6 102.113 260.462 103.704 262.086 106.252L262.387 106.727H260.869L260.776 106.6C260.301 105.945 260.1 105.71 259.358 105.115Z"
        fill="currentColor"
      />
      <path
        d="M213.099 103.664H217.606V119.692H218.897V103.664H223.41V102.427H213.146L213.099 103.664Z"
        fill="currentColor"
      />
      <path
        d="M229.943 119.693H239.672V118.455H231.207V111.628H239.672V110.391H231.207V103.664H239.672V102.434H229.943V119.693Z"
        fill="currentColor"
      />
      <path
        d="M268.913 119.693H278.643V118.455H270.177V111.628H278.643V110.391H270.177V103.664H278.643V102.434H268.913V119.693Z"
        fill="currentColor"
      />
      <path
        d="M285.176 102.434H286.439V118.455H293.507V119.693H285.176V102.434Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M305.309 102.427L297.907 119.692H299.298L301.792 113.902H310.077L312.571 119.692H313.962L306.56 102.427H305.309ZM302.327 112.665L305.931 104.146L309.515 112.665H302.327Z"
        fill="currentColor"
      />
      <path
        d="M334.377 113.253H324.54L324.527 113.26V112.023H335.921L335.881 112.364C335.339 116.643 331.401 120 326.914 120C321.859 120 317.747 116.015 317.747 111.08C317.747 106.145 321.826 102.133 326.841 102.133C329.977 102.133 332.986 103.765 334.517 106.279L334.798 106.747H333.307L333.213 106.627C331.575 104.493 329.375 103.37 326.867 103.37C322.554 103.37 319.037 106.947 319.037 111.1C319.037 115.252 322.635 118.763 326.894 118.763C330.351 118.763 333.561 116.369 334.377 113.253Z"
        fill="currentColor"
      />
      <path
        d="M342.467 119.693H352.19V118.455H343.725V111.628H352.19V110.391H343.725V103.664H352.19V102.434H342.467V119.693Z"
        fill="currentColor"
      />
      <path
        d="M376.817 102.434V119.693H375.553V104.748L368.158 119.693H367.402L359.987 104.741V119.693H358.723V102.434H360.294L360.381 102.601L367.77 117.626L375.293 102.434H376.817Z"
        fill="currentColor"
      />
      <path
        d="M155.574 24.2527H164.955L164.962 24.2594C164.734 20.6485 164.24 19.1507 162.461 16.9842C160.294 14.3764 156.463 12.7649 152.464 12.7649C145.182 12.7649 139.465 17.5392 139.465 23.7044C139.465 26.6465 140.91 29.5352 143.183 31.3674C144.46 32.4239 145.958 33.253 147.623 33.9752C147.918 34.1113 148.409 34.3119 149.09 34.5898C149.814 34.8852 150.752 35.268 151.896 35.7539C154.671 36.8639 155.841 38.0875 155.841 39.7525C155.841 41.7519 154.23 43.3032 152.177 43.3032C149.85 43.3032 148.345 41.364 148.345 38.3082H138.85V39.5319C138.85 46.7469 144.186 51.247 152.29 51.247C160.395 51.247 165.229 47.0277 165.229 39.9197C165.229 35.0852 162.842 32.0895 156.623 29.2544L152.07 27.2015C149.903 26.1985 148.907 25.0952 148.907 23.5372C148.907 21.9792 150.405 20.702 152.183 20.702C154.183 20.702 155.239 21.7519 155.574 24.2527Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M217.212 21.812C208.546 21.812 202.22 28.1377 202.22 36.8037V36.8103C202.22 45.249 208.713 51.4677 217.266 51.4677C225.818 51.4677 232.204 45.1286 232.204 36.6365C232.204 28.1443 225.878 21.812 217.212 21.812ZM217.266 43.6977C213.434 43.6977 210.545 40.6418 210.545 36.5897V36.583C210.545 32.698 213.601 29.5887 217.266 29.5887C220.93 29.5887 223.872 32.6445 223.872 36.6432C223.872 40.6418 221.097 43.6977 217.266 43.6977Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M197.921 21.8521V51.2403H190.091V47.5224C188.76 50.1838 185.925 51.4676 181.485 51.4676C173.1 51.4676 167.382 45.4696 167.382 36.6364C167.382 27.8033 173.267 21.7585 181.652 21.7585C185.871 21.7585 188.539 22.9821 190.091 25.7036V21.8521H197.921ZM175.707 36.6297C175.707 40.6284 178.763 43.6843 182.648 43.6843C186.533 43.6843 189.589 40.6886 189.589 36.5763C189.589 32.4639 186.647 29.5752 182.595 29.5752C178.543 29.5752 175.707 32.6311 175.707 36.6297Z"
        fill="currentColor"
      />
      <path
        d="M190.478 13.1994L193.641 17.4188C191.642 18.8029 189.589 19.5251 187.476 19.5251C186.7 19.5251 185.865 19.4114 184.975 19.2442L183.364 18.9099C181.973 18.6291 180.863 18.4686 180.087 18.4686C178.924 18.4686 177.981 18.7427 176.149 19.5786L172.932 15.1386C175.32 13.8079 177.038 13.1994 178.763 13.1994C179.76 13.1994 180.763 13.3666 182.595 13.6943C184.648 14.1423 185.149 14.1958 186.373 14.1958C187.931 14.1958 188.646 13.9751 190.478 13.1994Z"
        fill="currentColor"
      />
      <path
        d="M177.065 79.3714H167.182C165.183 83.4837 162.127 85.59 158.242 85.59C152.638 85.59 148.305 80.7622 148.305 74.597C148.305 68.4319 152.578 63.7712 158.188 63.7712C162.24 63.7712 165.684 66.2654 167.182 70.264H177.065C176.677 67.8234 176.289 66.934 174.678 64.3797C171.067 58.6625 165.129 55.4395 158.242 55.4395C147.362 55.4395 138.864 63.7712 138.864 74.597C138.864 85.4229 147.469 93.9217 158.355 93.9217C167.402 93.9217 174.958 88.0909 177.065 79.3714Z"
        fill="currentColor"
      />
      <path
        d="M181.358 64.5336H189.241V68.3785C190.793 65.657 192.739 64.4935 195.962 64.4935H196.182V72.8252C191.408 72.9388 189.629 74.323 189.629 77.8803V93.9285H181.358V64.5336Z"
        fill="currentColor"
      />
      <path d="M200.482 61.7719H208.807V55.4395H200.482V61.7719Z" fill="currentColor" />
      <path d="M200.482 93.9217H208.807V64.5335H200.482V93.9217Z" fill="currentColor" />
      <path
        d="M234.865 73.213H234.864C234.357 67.8314 230.474 64.5069 224.648 64.5069C218.375 64.5069 213.434 68.5591 213.434 73.6677C213.434 77.2251 215.935 80.4414 219.318 81.3307L224.206 82.6079C226.259 83.1027 227.423 84.1057 227.423 85.3829C227.423 86.5464 226.085 87.4892 224.534 87.4892C222.702 87.4892 221.592 86.5999 221.371 84.9349H213.1C213.708 90.6587 217.653 94.1559 224.206 94.1559C230.759 94.1559 235.754 90.097 235.754 84.7142C235.754 83.1027 235.313 81.6049 234.477 80.3812C232.926 78.1612 231.756 77.5527 226.039 75.9947C223.043 75.1589 221.766 74.2161 221.766 72.9389C221.766 71.8824 222.876 71.1602 224.373 71.1602C225.871 71.1602 226.593 71.8222 226.761 73.213H234.864L234.865 73.2264V73.213Z"
        fill="currentColor"
      />
      <path
        d="M250.238 64.5335V55.4395H241.913V64.5335H237.861V70.8057H241.913V93.9217H250.238V70.8057H254.236V64.5335H250.238Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M256.389 79.4851C256.389 70.8258 262.715 64.4934 271.381 64.4934C280.047 64.4934 286.373 70.8258 286.373 79.3179C286.373 87.81 279.987 94.1424 271.435 94.1424C262.882 94.1424 256.383 87.9237 256.383 79.4851H256.389ZM264.708 79.2577C264.708 83.3099 267.596 86.3657 271.428 86.3657C275.259 86.3657 278.034 83.3166 278.034 79.3112C278.034 75.3059 275.092 72.2634 271.428 72.2634C267.763 72.2634 264.708 75.3727 264.708 79.2577Z"
        fill="currentColor"
      />
      <path
        d="M277.285 52.3369L277.287 52.3398L265.182 58.6091L267.282 63.136L279.666 57.8669L277.287 52.3398L277.292 52.3369H277.285Z"
        fill="currentColor"
      />
      <path
        d="M304.307 93.9217L314.303 65.7305H305.691L300.308 82.7616L294.979 65.7305H286.373L296.369 93.9217H304.307Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M344.842 64.5336V93.9284H337.011V90.2039C335.681 92.8719 332.846 94.1491 328.406 94.1491C320.02 94.1491 314.303 88.1511 314.303 79.3246C314.303 70.4981 320.188 64.4399 328.573 64.4399C332.792 64.4399 335.46 65.6636 337.011 68.3851V64.5336H344.842ZM322.622 79.3179C322.622 83.3166 325.677 86.3724 329.562 86.3724C333.447 86.3724 336.503 83.3768 336.503 79.2644C336.503 75.1521 333.561 72.2701 329.509 72.2701C325.457 72.2701 322.622 75.3193 322.622 79.3179Z"
        fill="currentColor"
      />
      <path
        d="M337.399 55.8876L340.562 60.1069C338.563 61.4978 336.51 62.22 334.397 62.22C333.621 62.22 332.785 62.1063 331.896 61.9391L330.285 61.6048C328.9 61.3239 327.784 61.1568 327.008 61.1568C325.845 61.1568 324.902 61.4309 323.063 62.2668L319.847 57.8268C322.234 56.4961 323.952 55.8809 325.677 55.8809C326.674 55.8809 327.67 56.0481 329.509 56.3824C331.562 56.8238 332.063 56.8839 333.287 56.8839C334.845 56.8839 335.56 56.6633 337.393 55.8876H337.399Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M364.126 64.4935C355.467 64.4935 349.135 70.8258 349.135 79.4851H349.128C349.128 87.9238 355.627 94.1425 364.18 94.1425C372.732 94.1425 379.118 87.8101 379.118 79.318C379.118 70.8258 372.785 64.4935 364.126 64.4935ZM364.18 86.3725C360.348 86.3725 357.46 83.3166 357.46 79.2645C357.46 75.3795 360.515 72.2702 364.18 72.2702C367.844 72.2702 370.786 75.3126 370.786 79.318C370.786 83.3233 368.011 86.3725 364.18 86.3725Z"
        fill="currentColor"
      />
      <path
        d="M64.5001 0C52.5108 0 41.6248 4.81445 33.6676 12.6112C36.0079 12.0829 38.3817 11.7285 40.7823 11.5614C41.899 11.4811 43.0156 11.4477 44.0855 11.4477C44.7074 11.4477 45.3226 11.4611 45.9377 11.4878C51.4142 8.35841 57.7465 6.553 64.4934 6.553C71.2403 6.553 77.5793 8.35173 83.0625 11.4878C83.6776 11.4611 84.2861 11.4477 84.9013 11.4477C85.9779 11.4477 87.0879 11.4878 88.2112 11.5614C90.6118 11.7285 92.9856 12.0829 95.3259 12.6112C87.3687 4.81445 76.4894 0 64.5001 0Z"
        fill="#85CEE4"
      />
      <path
        d="M67.5761 88.071C68.412 87.4024 69.6223 86.2656 70.3979 85.5301C70.8209 85.9531 71.8472 86.9776 72.8434 87.9721L72.8447 87.9734L72.8478 87.9765L72.8879 88.0164C73.8127 88.9397 74.7035 89.829 75.0519 90.1774C73.7881 91.4278 72.4441 92.6046 71.0332 93.6946C68.987 95.2793 66.8071 96.6835 64.5002 97.8938C58.3952 101.097 51.4544 102.909 44.0923 102.909C19.7794 102.909 0 83.1295 0 58.8166C0 39.5789 12.3905 23.1763 29.6022 17.1716C33.213 15.9078 37.0445 15.1054 41.0097 14.8313C42.0328 14.7644 43.0559 14.7243 44.0923 14.7243C50.0101 14.7243 55.6537 15.9012 60.8091 18.0275C58.3618 19.5387 56.1351 21.1502 54.1558 22.6614C50.9529 21.7654 47.7232 21.284 44.0923 21.284C40.4614 21.284 36.9509 21.7988 33.6276 22.7684C30.0903 23.7982 26.767 25.3361 23.7379 27.2953C13.4002 33.9888 6.553 45.617 6.553 58.8166C6.553 79.5187 23.3969 96.3559 44.0923 96.3559C48.9937 96.3559 53.6744 95.4064 57.9673 93.6946C60.2675 92.7785 62.4541 91.6351 64.5002 90.3044C65.5634 89.609 66.5932 88.8668 67.5761 88.071Z"
        fill="#85CEE4"
      />
      <path
        d="M108.231 38.4553C105.884 35.7673 103.083 33.4871 99.9465 31.7352C95.4864 29.2477 90.3644 27.8234 84.908 27.8234V34.3764C91.5279 34.3764 97.5326 37.0244 101.932 41.3172C104.674 43.9852 106.794 47.2952 108.044 50.9996C108.873 53.4537 109.328 56.0749 109.328 58.8097C109.328 72.2768 98.3684 83.2364 84.9013 83.2364V89.7894C101.986 89.7894 115.881 75.8876 115.881 58.8097C115.881 51.0197 112.986 43.905 108.225 38.4486L108.231 38.4553Z"
        fill="#85CEE4"
      />
      <path
        d="M27.0612 41.3241C31.4677 37.0379 37.4724 34.3832 44.0856 34.3832V27.8303C38.6292 27.8303 33.5005 29.2545 29.0471 31.742C25.9111 33.4939 23.1093 35.7741 20.7623 38.4622C16.0013 43.9118 13.106 51.0332 13.106 58.8233C13.106 75.9079 27.0077 89.8029 44.0856 89.8029V83.2499C30.6185 83.2499 19.659 72.2903 19.659 58.8233C19.659 56.0951 20.1137 53.4672 20.9428 51.0132C22.1932 47.3087 24.3129 43.9988 27.0545 41.3308L27.0612 41.3241Z"
        fill="#85CEE4"
      />
      <path
        d="M70.271 73.5807C74.176 77.4857 79.3649 79.6389 84.8948 79.6389C90.4248 79.6389 95.6137 77.4857 99.5187 73.5807L95.1657 69.2276C92.4241 71.9759 88.7731 73.4871 84.8948 73.4871C81.0165 73.4871 77.3656 71.9759 74.624 69.2276C71.8825 66.4861 70.3646 62.8351 70.3646 58.9568C70.3646 55.0785 71.8758 51.4275 74.624 48.686C77.3656 45.9444 81.0165 44.4265 84.8948 44.4265C88.7731 44.4265 92.4241 45.9377 95.1657 48.686L99.5187 44.3329C95.6137 40.4279 90.4248 38.2747 84.8948 38.2747C79.3649 38.2747 74.176 40.4279 70.271 44.3329C66.3659 48.238 64.2128 53.4269 64.2128 58.9568C64.2128 64.4867 66.3659 69.6756 70.271 73.5807Z"
        fill="#85CEE4"
      />
      <path
        d="M48.1377 46.1051C47.0611 45.0285 45.6235 44.4334 44.0922 44.4334C42.561 44.4334 41.1233 45.0285 40.0401 46.1117C38.9568 47.195 38.3617 48.6393 38.3617 50.1639C38.3617 51.6885 38.9568 53.1261 40.0401 54.2094C41.1233 55.2926 42.561 55.8877 44.0922 55.8877C47.2684 55.8877 50.2507 57.1181 52.4908 59.3648C54.7308 61.6116 55.9679 64.5939 55.9679 67.7634C55.9679 70.9329 54.7308 73.9152 52.4908 76.1619C50.244 78.4087 47.2684 79.6457 44.0922 79.6457C40.916 79.6457 37.9404 78.4153 35.6937 76.1686C33.4536 73.9219 32.2166 70.9396 32.2166 67.7701H38.3684C38.3684 69.2946 38.9635 70.739 40.0467 71.8222C41.1233 72.8988 42.5676 73.4939 44.0922 73.4939C45.6168 73.4939 47.0544 72.8988 48.1377 71.8155C49.2209 70.7323 49.8161 69.288 49.8161 67.7634C49.8161 66.2388 49.2209 64.8012 48.1377 63.7179C47.0611 62.6347 45.6235 62.0395 44.0922 62.0395C40.916 62.0395 37.9337 60.8025 35.687 58.5558C33.4469 56.3157 32.2099 53.3334 32.2099 50.1639C32.2099 46.9944 33.4469 44.0121 35.687 41.7654C37.9337 39.5186 40.916 38.2816 44.0922 38.2816C47.2684 38.2816 50.244 39.5119 52.4908 41.7587C54.7308 44.0054 55.9679 46.9877 55.9679 50.1572H49.8161C49.8161 48.626 49.2209 47.1883 48.1377 46.1051Z"
        fill="#85CEE4"
      />
      <path
        d="M87.9841 14.8313C91.9561 15.1054 95.7809 15.9078 99.3917 17.1716L99.3783 17.1649C116.59 23.1763 128.98 39.5722 128.98 58.8099C128.98 83.1229 109.201 102.902 84.8882 102.902C84.0791 102.902 83.2834 102.875 82.4876 102.835C82.3728 102.917 82.2539 102.999 82.1404 103.079C81.8705 103.267 81.6315 103.433 81.5515 103.504C81.4779 103.551 74.3699 108.546 70.3245 119.987H60.1874C58.7899 112.357 56.9376 109.275 52.0831 105.59C56.9911 104.688 61.6919 103.076 66.0851 100.769C68.6662 99.4184 71.1269 97.8069 73.4472 96.0416C73.9687 95.6471 74.4301 95.286 74.8447 94.9584C75.1122 95.0386 75.393 95.1189 75.7073 95.2058C78.6495 95.948 81.7254 96.3492 84.8949 96.3492C105.597 96.3492 122.434 79.5053 122.434 58.8099C122.434 45.6103 115.587 33.9821 105.256 27.2887C102.234 25.3294 98.9036 23.7915 95.3663 22.7617C92.043 21.7988 88.5324 21.2773 84.9015 21.2773C80.0002 21.2773 75.3195 22.2201 71.0266 23.9319C68.7263 24.848 66.5398 25.9914 64.4936 27.3221C62.3205 28.5324 59.2379 31.3141 58.1814 32.3839C57.3521 31.6063 56.3769 30.6214 55.4275 29.6627C54.777 29.0057 54.1385 28.3609 53.5675 27.8035C56.844 24.293 62.1867 20.9496 64.4936 19.7393C70.5986 16.5364 77.5395 14.7243 84.9015 14.7243C85.938 14.7243 86.9677 14.7577 87.9841 14.8313Z"
        fill="#85CEE4"
      />
      <path
        d="M384.595 59.0642C384.595 62.0534 382.187 64.5811 379.098 64.5811C376.009 64.5811 373.648 62.0935 373.648 59.0977C373.648 56.1018 376.042 53.5808 379.131 53.5808C382.221 53.5808 384.601 56.055 384.601 59.0642H384.595ZM383.318 59.0642C383.318 56.7706 381.492 54.8781 379.131 54.8781C376.771 54.8781 374.945 56.804 374.945 59.0977C374.945 61.3913 376.737 63.2838 379.105 63.2838C381.472 63.2838 383.324 61.3579 383.324 59.0642H383.318ZM380.489 59.7931L381.586 61.4917H380.114L379.339 60.0339H378.322V61.4917H377.045V56.3158H379.279C380.656 56.3158 381.432 56.9645 381.432 58.108C381.432 58.9037 381.091 59.4855 380.496 59.7931H380.489ZM379.279 59.0308C379.86 59.0308 380.201 58.7098 380.201 58.1548C380.201 57.5998 379.86 57.2988 379.279 57.2988H378.322V59.0308H379.279Z"
        fill="currentColor"
      />
    </svg>
  )
}
