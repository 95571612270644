import { tv, type VariantProps } from 'tailwind-variants'

export const header = tv({
  slots: {
    base: 'relative z-50 bg-white flex items-center w-full h-20 md:h-28',
    container:
      'relative z-10 flex items-center mx-auto w-full h-full max-w-screen-xl px-6 md:px-8 gap-8 lg:gap-12',
    brand: 'w-32 md:w-48 text-blue-500',
    nav: [
      'flex lg:items-center lg:mr-auto z-50',
      'w-full lg:w-auto',
      'absolute lg:relative',
      'left-0 lg:left-auto',
      'top-full lg:top-auto',
      'overflow-hidden',
      'bg-blue-100 lg:bg-transparent',
      'px-6 py-8 lg:px-0 lg:py-0',
      'lg:!visible lg:!opacity-100 lg:!pointer-events-auto'
    ],
    menu: 'flex flex-col gap-10 lg:flex-row',
    menuItem: '',
    cta: 'ml-auto hidden sm:flex',
    hamburguer: 'flex items-center lg:hidden ml-auto sm:ml-0'
  },
  variants: {
    opened: {
      false: {
        nav: ['pointer-events-none', 'invisible', 'opacity-0']
      },
      true: {
        nav: ['pointer-events-auto', 'visible', 'opacity-100']
      }
    }
  }
})

export type HeaderVariantProps = VariantProps<typeof header>
