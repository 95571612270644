import { tv, type VariantProps } from 'tailwind-variants'

export const button = tv({
  slots: {
    base: [
      'inline-flex',
      'box-border',
      'select-none',
      'appearance-none',
      'outline-none',
      'disabled:pointer-events-none',
      'disabled:opacity-60',
      'rounded-[50vh]',
      'transition-colors duration-300'
    ],
    icon: ['shrink-0']
  },
  variants: {
    appearance: {
      text: '',
      solid: '',
      ghost: '',
      outline: ''
    },
    color: {
      light: '',
      dark: '',
      primary: '',
      secondary: ''
      // success: '',
      // warning: '',
      // danger: ''
    },
    size: {
      xs: {
        base: 'text-xs/5 leading-1 px-3 py-2 gap-2',
        icon: 'w-3 h-3'
      },
      sm: {
        base: 'text-sm/5 leading-1 px-3 py-2.5 gap-2',
        icon: 'w-3 h-3'
      },
      md: {
        base: 'text-base/5 leading-1 px-4 py-3 gap-3',
        icon: 'w-4 h-4'
      },
      lg: {
        base: 'text-lg/5 px-5 py-4 gap-3',
        icon: 'w-4 h-4'
      }
    },
    fullWidth: {
      false: '',
      true: 'w-full',
      xs: 'sm:w-auto',
      sm: 'md:w-auto',
      md: 'lg:w-auto',
      lg: 'xl:w-auto'
    },
    isIconOnly: {
      true: ''
    },
    isLoading: {
      true: ''
    }
  },
  compoundVariants: [
    // focus
    {
      appearance: ['ghost', 'outline', 'solid'],
      className: 'focus:outline-none focus:ring-4'
    },

    // appearance
    {
      appearance: 'outline',
      className: 'border'
    },
    {
      appearance: ['solid', 'ghost', 'outline'],
      className:
        'font-semibold items-center justify-center text-center border border-transparent'
    },
    {
      appearance: ['ghost', 'outline'],
      className: 'bg-transparent'
    },
    {
      appearance: 'text',
      className: {
        base: 'p-0 rounded-none focus:ring-offset-8'
      }
    },

    // light color
    {
      appearance: 'text',
      color: 'light',
      className: {
        base: 'text-white hover:text-blue-100'
      }
    },
    {
      appearance: 'solid',
      color: 'light',
      className: {
        base: 'bg-white border-white text-blue-100 focus:ring-white/50 hover:border-blue-500 hover:border-blue-100 hover:bg-blue-100'
      }
    },
    {
      appearance: 'ghost',
      color: 'light',
      className: {
        base: 'text-black focus:ring-black/50 hover:bg-black hover:border-black hover:text-white'
      }
    },
    {
      appearance: 'outline',
      color: 'light',
      className: {
        base: 'text-black border-black focus:ring-black/50 hover:bg-black hover:text-white'
      }
    },
    // dark color
    {
      appearance: 'text',
      color: 'dark',
      className: {
        base: 'text-black hover:text-gray-500'
      }
    },
    {
      appearance: 'solid',
      color: 'dark',
      className: {
        base: 'bg-black border-black text-white focus:ring-black/50 hover:border-gray-500 hover:bg-gray-500'
      }
    },
    {
      appearance: 'ghost',
      color: 'dark',
      className: {
        base: 'text-black focus:ring-black/50 hover:bg-black hover:border-black hover:text-white'
      }
    },
    {
      appearance: 'outline',
      color: 'dark',
      className: {
        base: 'text-black border-black focus:ring-black/50 hover:bg-black hover:text-white'
      }
    },
    // primary color
    {
      appearance: 'text',
      color: 'primary',
      className: {
        base: 'text-blue-500 hover:text-blue-900'
      }
    },
    {
      appearance: 'solid',
      color: 'primary',
      className: {
        base: 'bg-blue-500 border-blue-500 text-white focus:ring-blue-500/50 hover:border-blue-900 hover:bg-blue-900'
      }
    },
    {
      appearance: 'ghost',
      color: 'primary',
      className: {
        base: 'text-blue-500 focus:ring-blue-500/50 hover:bg-blue-500 hover:border-blue-500 hover:text-white'
      }
    },
    {
      appearance: 'outline',
      color: 'primary',
      className: {
        base: 'text-blue-500 border-blue-500 focus:ring-blue-500/50 hover:bg-blue-500 hover:text-white'
      }
    },
    // secondary color
    {
      appearance: 'text',
      color: 'secondary',
      className: {
        base: 'text-blue-300 hover:text-blue-500'
      }
    },
    {
      appearance: 'solid',
      color: 'secondary',
      className: {
        base: 'bg-blue-300 border-blue-300 text-blue-900 focus:ring-blue-300/50 hover:border-blue-500 hover:bg-blue-500 hover:text-white'
      }
    },
    {
      appearance: 'ghost',
      color: 'secondary',
      className: {
        base: 'text-blue-300 focus:ring-blue-300/50 hover:bg-blue-300 hover:border-blue-300 hover:text-blue-900'
      }
    },
    {
      appearance: 'outline',
      color: 'secondary',
      className: {
        base: 'text-blue-300 border-blue-300 focus:ring-blue-300/50 hover:bg-blue-300 hover:text-blue-900'
      }
    },

    // fullWidth
    {
      fullWidth: ['xs', 'sm', 'md', 'lg'],
      className: 'w-full'
    }
  ],
  defaultVariants: {
    appearance: 'text',
    color: 'dark',
    size: 'md',
    fullWidth: false,
    isIconOnly: false,
    isLoading: false
  }
})

export type ButtonVariantProps = VariantProps<typeof button>
